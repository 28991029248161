import { useRouter } from 'next/router';
import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { api } from 'services/apiClient';
import { ThemeType } from 'utils/eventos';

interface ITicket {
  id: string;
  seq: number;
  name: string | null;
  email: string | null;
  status: string;
}
interface IRaffleProps {
  id: string;
  createdAt: string;
  updatedAt: string;
  description: string;
  reward: string;
  userId: string;
  tickets: ITicket[];
  thirdPlace?: string;
  runnerUp?: string;
  drawTime: {
    value: string
  }; 
  drawDate: {
    value: string,
    timestamp: number
  }; 
  type: string; 
}

interface EventoProps {
  evento: IEventProps;
  setEvento: Dispatch<SetStateAction<IEventProps>>;
  eventos: IEventProps[];
  setEventos: Dispatch<SetStateAction<IEventProps[]>>;
  selectedType: EventType;
  setSelectedType: Dispatch<SetStateAction<EventType>>;
  eventId: string;
  setEventId: Dispatch<SetStateAction<string>>;
  theme: string;
  setTheme: Dispatch<SetStateAction<string>>;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  stepActive: number;
  setStepActive: Dispatch<SetStateAction<number>>;
  mensagemApresentacao: string;
  setMensagemApresentacao: Dispatch<SetStateAction<string>>;
  setInviteMessage: Dispatch<SetStateAction<string>>;
  inviteMessage: string;
  imgsEvento: IPhotosEvent[];
  setImgsEvento: Dispatch<SetStateAction<IPhotosEvent[]>>;
  handleGetEvent: () => Promise<void>;
  infoTheme: IThemeProps;
  setInfoTheme: Dispatch<SetStateAction<IThemeProps>>;
  temaBackground: string;
  setTemaBackground: Dispatch<SetStateAction<string>>;
  raffle: IRaffleProps;
  setRaffle: Dispatch<SetStateAction<IRaffleProps>>;
  handleGetRaffle: (raffleId: string) => Promise<any>;
  resetRaffle: () => void;
  isGuest?: boolean;
}

export interface IEventProps {
  eventTime: string;
  eventDate: string;
  id: string;
  name: string;
  userId: string;
  pregnancyWeeks?: number;
  theme: ThemeType;
  babyName: string;
  eventType: EventType;
  inviteModel: string;
  invite: string;
  inviteCover: string;
  inviteFooter: string;
  inviteBackgroundColor: string;
  partyBgElement: string;
  inviteBgElement: string;
  welcomeMessage: string;
  createdAt: string;
  updatedAt: string;
  gender: string;
  maleBabyName: string;
  femaleBabyName: string;
  expectedBirthDate: string;
  slug: string;
  cover: string;
  rightImage: string;
  leftImage: string;
  footer: string;
  birthdayAge: string;
  eventPlace: string;
  background: string;
  address: IAddressProps;
  online: boolean;
  hasEvent: boolean;
  inviteMessage: string;
  raffle?: {
    id: string;
    reward: string;
    thirdPlace: string;
    runnerUp: string;
    drawData: string;
    drawTime: string;
  };
  shared: boolean;
  fontFamily: string;
  fontColor: string;
}

export interface IAddressProps {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  zipcode: string;
}

export interface IPhotosEvent {
  id: string;
  url: string;
  partyId: string;
}

export interface IThemeProps {
  colors?: {
    primary?: string;
    secondary?: string;
  };
  fonts?: {
    primary?: string;
    secondary?: string;
  };
}

export const eventTypes = {
  cha_revelacao: 'Chá revelação',
  cha_fralda: 'Chá de fraldas',
  cha_bebe: 'Chá de bebê',
  aniversario: 'Aniversário',
  cha_rifa: 'Chá Rifa',
  none: '',
};

export type EventType = keyof typeof eventTypes;

const EventoContext = createContext<EventoProps>({} as EventoProps);

export const EventoProvider: React.FC = ({ children }) => {
  const [evento, setEvento] = useState({} as IEventProps);
  const [eventos, setEventos] = useState<IEventProps[]>([]);
  const [selectedType, setSelectedType] = useState<EventType>('none');
  const [eventId, setEventId] = useState('');
  const [theme, setTheme] = useState('');
  const [step, setStep] = useState(1);
  const [stepActive, setStepActive] = useState(0);
  const [mensagemApresentacao, setMensagemApresentacao] = useState('');
  const [inviteMessage, setInviteMessage] = useState('');
  const [imgsEvento, setImgsEvento] = useState<IPhotosEvent[]>([]);
  const [infoTheme, setInfoTheme] = useState<IThemeProps>({} as IThemeProps);
  const [temaBackground, setTemaBackground] = useState<string>('');
  const [raffle, setRaffle] = useState<IRaffleProps>({} as IRaffleProps);

  const resetRaffle = () => {
    setRaffle({} as IRaffleProps);
  };

  const handleGetRaffle = async (raffleId: string) => {
    try {
      const { data } = await api.get<IRaffleProps>(`raffles/${raffleId}`);
      setRaffle(data);
      return data
    } catch (error) {
      console.log(error);
      return error
    }
  };

  const { pathname } = useRouter();

  const isGuest = useMemo(() => (pathname === '/[slug]' ? true : false), [pathname]);

  const handleGetEvent = useCallback(async () => {
    try {
      if (!evento.id) return;
      const { data } = await api.get<IEventProps>(`parties/${evento.id}`);
      setEvento(data);
    } catch (error) {
      console.log(error);
    }
  }, [evento.id]);

  useEffect(() => {
    async function handleGetPhotosEvent() {
      try {
        if (!evento.id) return;

        const { data } = await api.get<{ values: IPhotosEvent[] }>(`parties/${evento.id}/photos`);
        setImgsEvento(data.values);
      } catch (error) {
        console.log(error);
      }
    }
    handleGetPhotosEvent();
  }, [evento.id]);

  return (
    <EventoContext.Provider
      value={{
        resetRaffle,
        handleGetRaffle,
        raffle,
        setRaffle,
        evento,
        setEvento,
        eventos,
        setEventos,
        selectedType,
        setSelectedType,
        temaBackground,
        inviteMessage,
        setInviteMessage,
        setTemaBackground,
        eventId,
        setEventId,
        theme,
        setTheme,
        step,
        setStep,
        stepActive,
        setStepActive,
        mensagemApresentacao,
        setMensagemApresentacao,
        imgsEvento,
        setImgsEvento,
        handleGetEvent,
        infoTheme,
        setInfoTheme,
        isGuest,
      }}
    >
      {children}
    </EventoContext.Provider>
  );
};

export function useEvento(): EventoProps {
  const context = useContext(EventoContext);

  if (!context) {
    throw new Error('useEvento must be used within an useEvento');
  }

  return context;
}
